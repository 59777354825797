
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { MatchTable } from '@/definitions/shared/types'
import ContactsImportMatchTable from '@/components/pages/contacts/import/flow/ContactsImportMatchTable.vue'
import CarrierLookupMatchTable from '@/components/pages/services/carrierLookup/flow/CarrierLookupMatchTable.vue'

export default defineComponent({
  name: 'ContactsImportMatchModal',
  components: {
    CarrierLookupMatchTable,
    ContactsImportMatchTable,
    TmButton,
    TmModal,
  },
  props: {
    matchTable: {
      type: String as PropType<MatchTable>,
      default: 'contacts',
    },
    subtitle: {
      type: String,
    },
  },
  setup(props) {
    const matchTables: Record<string, string> = {
      contacts: 'ContactsImportMatchTable',
      carrierLookup: 'CarrierLookupMatchTable',
    }
    const sizeMap: Record<string, string> = {
      contacts: 'full',
      carrierLookup: 'xLarge',
    }

    const matchTableName = computed<string>(() => matchTables[props.matchTable])
    const sizeName = computed<string>(() => sizeMap[props.matchTable])

    return {
      matchTableName,
      sizeName,
    }
  },
})
